<template>
  <div>
    <v-card flat>
      <v-overlay v-model="overlay"
                 absolute contained
      ></v-overlay>
      <v-toolbar color="transparent" dense flat>
        <v-toolbar-title>mobile money</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-text-field
            v-model="create.phone"
            :suffix="fone"
            dense
            label="phone"
        ></v-text-field>
        <v-text-field
            v-model="create.amount"
            dense
            error-count=""
            label="amount"
            suffix="GHS"
            type="number"
        ></v-text-field>
        <v-text-field v-model="create.email" append-icon="mdi-at" dense disabled label="email"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
            :disabled="$v.create.$invalid"
            color="info"
            outlined
            text
            @click="submit"
        >
          pay with mobile money
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
        v-model="snack.bar"
        :color="snack.color"
        :multi-line="snack.multiLine"
        :timeout="snack.timeout"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import {validationMixin} from "vuelidate";
import Utils from "@/mixins/utils";

const {
  required,
  minLength,
  maxLength,
  minValue,
  numeric,
  email,
} = require("vuelidate/lib/validators");

export default {
  name: "momoPayment",
  props: {
    payId: {
      type: String
    }
  },
  mixins: [Utils, validationMixin],
  data: () => ({
    overlay: false,
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      timeout: 12000,
      text: "Processing ...",
    },
    salesData: {},
    create: {
      amount: 10,
      email: null,
      phone: "0551234987",
    },
    provider: null
  }),
  validations: {
    create: {
      amount: {required, numeric, minValue: minValue(10)},
      email: {required, email},
      phone: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      contact: {},
    },
  },
  computed: {
    fone: function () {
      let f = this.create.phone;
      if (f === null) {
        return "MoMo Number";
      } else {
        if (f.length > 2) {
          let s = f.substring(0, 3);
          console.log(s);
          if (s === "020" || s === "050") {
            return "VODAFONE";
          } else if (s === "024" || s === "054" || s === "055") {
            return "MTN";
          } else if (s === "027" || s === "026" || s === "057" || s === "056") {
            return "AIRTEL/TIGO";
          } else {
            return "MoMo Number";
          }
        } else {
          return "MMo Number";
        }
      }
    },
  },
  created() {
    this.salesData = JSON.parse(this._decode(this.payId));
    this.create.amount = this.salesData.price;
    this.account();
  },
  methods: {
    account() {
      let self = this;
      Restful.accounts
          .contacts()
          .then((response) => {
            console.log(response.data);
            this.contact = response.data;
            this.create.email = response.data.email;
            this.create.phone = response.data.mobile_phone;

            console.log(this.contact);
          })
          .catch((error) => {
            self.snack.text = error.response.data.message;
            self.snack.timeout = 14000;
            self.snack.color = "error";
          });
    },
    foneType() {
      // provider: MTN -> mtn; AirtelTigo -> tgo; Vodafone -> vod; M-Pesa -> mpesa

      if (this.fone === 'VODAFONE') {
        return 'vod';
      } else if (this.fone === 'MTN') {
        return 'mtn';
      } else if (this.fone === 'AIRTEL/TIGO') {
        return 'tgo';
      } else {
        return "error"
      }
    },
    submit() {
      this.overlay = true;
      let medics_available_timesid = this.salesData.medicsAvailableId;
      let amount = this.create.amount;
      let phone = this.create.phone;
      let provider = this.foneType();
      let currency = "GHS";

      let data = {medics_available_timesid, amount, phone, currency, provider};
      console.log(data);

      this.snack.bar = true;
      this.snack.text = "Processing payment ...";
      let self = this;
      Restful.checkout
          .momo(data)
          .then((response) => {
            this.snack.color = "success";
            console.log(response)
            if (response.status === 200) {
              this.snack.text = "Pay process has started, and it currently says '" +
                  this.uppCase(response.data.message) + "', " +
                  "please check your phone for further instructions";
            } else {
              this.snack.text = response.data;
            }
            this.snack.timeout = -1;

            setTimeout(() => {
              this.check(response.data.reference);
              this.overlay = false;
              this.$router.push({name: 'patient.appointments'})
            }, 6000);
          })
          .catch((error) => {
            self.snack.text = error.response.data.message;
            self.snack.timeout = -1;
            self.snack.color = "error";
            self.overlay = false;
          });
      this.$emit("refreshing", "data");
    },
    check(ref) {
      this.snack.bar = true;
      let reference = ref;
      let data = {reference};
      console.log(data);
      Restful.checkout
          .verifyMomo(data)
          .then((response) => {
            console.log(response.data);
            if (response.data === "success") {
              this.snack.color = "success";
              this.snack.text =
                  "Your payment is successfull, your account has been credited now";
            } else {
              this.snack.text = response.data;
            }
            this.snack.timeout = -1;
          })
          .catch((error) => {
            console.log(error);
            self.snack.color = "error";
            self.snack.text = error.response.data;
            self.snack.timeout = -1;
          });
    },
  },
}
</script>