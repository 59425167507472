<template>
  <v-dialog v-model="labDialog" max-width="1000" persistent>
    <v-card>
      <v-card-title>{{ offer }}'s offer</v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="view" :items-per-page="5"></v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Utils from "../../mixins/utils.js";

export default {
  props: {
    labDialog: Boolean,
    myId: {type: Number},
    data: {type: String},
    offer: {type: String}
  },
  mixins: [Utils],
  data() {
    return {
      view: [],
      headers: [
        {text: "service_name", value: "service_name"},
        {text: "detail", value: "detail"},
        {text: "pricing", value: "pricing"},
        {text: "instructions", value: "instructions"},
      ],
    };
  },
  created() {
    this.myView();
    console.log('view', this.view)
  },
  methods: {
    myView() {
      this.view = JSON.parse(this._decode(this.data));
      console.log('view', this.view)
    },
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closeLab", payload);
    },
  },
};
</script>
