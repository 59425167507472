<template>
  <div>
    <v-card flat>
      <v-overlay absolute v-model="overlay" contained> </v-overlay>
      <v-toolbar color="transparent" dense flat>
        <v-toolbar-title>Credit Card</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-text-field
          v-model="cc.full_name"
          dense
          label="full name"
        ></v-text-field>
        <v-text-field
          v-model="cc.email"
          dense
          disabled
          label="email address"
        ></v-text-field>
        <v-text-field
          v-model="cc.amount"
          :suffix="cc.currency"
          dense
          disabled
          label="amount"
          type="number"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <paystack
          :amount="cc.amount * 100"
          :callback="processPayment"
          :close="close"
          :currency="cc.currency"
          :email="cc.email"
          :embed="false"
          :paystackkey="cc.PUBLIC_KEY"
          :reference="reference"
          style="color: rgb(26 118 210); font-weight: 400; font-size: 14px; !mportant; text-transform: uppercase; padding: 7px; border: solid 1px rgba(0, 0, 0, 0.12); border-radius: 4px;"
        >
          pay with credit card
        </paystack>
      </v-card-actions>
    </v-card>

    <v-snackbar
      v-model="snack.bar"
      :color="snack.color"
      :multi-line="snack.multiLine"
      :timeout="snack.timeout"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import paystack from "vue-paystack";
import { validationMixin } from "vuelidate";
import Utils from "../../../mixins/utils.js";
import {
  email,
  minLength,
  minValue,
  numeric,
  required,
} from "vuelidate/lib/validators";

export default {
  name: "ccPayment",
  props: {
    payId: {
      type: String,
    },
  },
  components: { paystack },
  mixins: [Utils, validationMixin],
  data: () => ({
    overlay: false,
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      timeout: 12000,
      text: "Processing ...",
    },
    cc: {
      amount: 10,
      currency: "GHS",
      full_name: "Kwesi Aryee",
      email: "hey@me.com",
      PUBLIC_KEY: "pk_test_0343f07374f8e394b1e4fc7a43ee29df2ed83eaa",
    },
    contact: {},
    salesData: {},
  }),
  validations: {
    cc: {
      amount: { required, minValue, numeric },
      full_name: {
        required,
        minLength: minLength(5),
      },
      email: { required, email },
    },
  },
  computed: {
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 15; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
  },
  created() {
    this.salesData = JSON.parse(this._decode(this.payId));
    this.cc.amount = this.salesData.price;
    this.payKey();
    this.account();
  },
  methods: {
    account() {
      let self = this;
      Restful.accounts
        .contacts()
        .then((response) => {
          console.log(response.data);
          this.contact = response.data;
          this.cc.full_name =
            response.data.first_name + " " + response.data.last_name;
          this.cc.email = response.data.email;

          console.log(this.contact);
        })
        .catch((error) => {
          self.snack.text = error.response.data.message;
          self.snack.timeout = 14000;
          self.snack.color = "error";
        });
    },
    payKey() {
      let self = this;
      let identityKey = "public";
      let d = { identityKey };
      Restful.checkout
        .myKeys(d)
        .then((response) => {
          console.log(response.data);
          this.cc.PUBLIC_KEY = response.data;
          console.log(this.cc.PUBLIC_KEY);
        })
        .catch((error) => {
          self.snack.text = error.response.data.message;
          self.snack.timeout = 14000;
          self.snack.color = "error";
        });
    },
    async processPayment() {
      this.overlay = true;
      let amount = this.cc.amount;
      let email = this.cc.email;
      let reference = this.reference;
      let payStatus = "paid";
      let data = { amount, email, reference, payStatus };
      // console.log(data);
      this.snack.bar = true;
      setTimeout(() => {
        this.processing(data);
      }, 6000);
    },
    processing(data) {
      let amount = data.amount;
      let email = data.email;
      let phone = this.contact.mobile_phone;
      let medics_available_timesid = this.salesData.medicsAvailableId;
      console.log(this.salesData);
      let service_type = this.salesData.service_type;
      let reference = data.reference;
      let payStatus = data.payStatus;
      let da = {
        amount,
        email,
        phone,
        reference,
        service_type,
        payStatus,
        medics_available_timesid,
      };

      console.log(da);
      this.snack.bar = true;
      this.snack.text = "Updating accounts";
      let self = this;
      Restful.checkout
        .cc(da)
        .then((response) => {
          this.snack.color = "success";
          console.log(response);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text =
              "Payment is successfull and your current unit credit is updated to ";
            this.snack.timeout = -1;
            setTimeout(() => {
              this.overlay = false;
              this.$router.push({ name: "patient.appointments" });
            }, 3000);
          } else {
            this.snack.color = "error";
            this.snack.text = "Sorry, payment is not successful ";
            this.snack.timeout = -1;
          }
        })
        .catch((error) => {
          self.snack.text = error.response.data.message;
          self.snack.timeout = -1;
          self.snack.color = "error";
          self.overlay = false;
        });
    },
    close: () => {
      console.log("close");
      this.snack.bar = true;
      this.snack.color = "success";
      this.snack.text = "You closed checkout page";
      this.snack.timeout = 6000;
    },
  },
};
</script>
