<template>
  <div>
    <v-card flat>
      <v-toolbar color="transparent" dense flat>
        <v-toolbar-title>cash</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p>Alternatively you can decide to make payments directly to the provider prior to the services being rendered.
          On the other hand the provider will have to approve this payment method before the services can be
          rendered.</p>
        <p>Appointment can only be honoured if payment has been approved by the provider.</p>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" small text @click="submit">Submit payment method for approval</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
        v-model="snack.bar"
        :color="snack.color"
        :multi-line="snack.multiLine"
        :timeout="snack.timeout"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Utils from "@/mixins/utils";
import Restful from "@/services/RestFul";

export default {
  name: "cashPayment",
  props: {
    payId: {
      type: String
    }
  },
  mixins: [Utils],
  data: () => ({
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      timeout: 12000,
      text: "Processing ...",
    },
    salesData: {}
  }),
  created() {
    this.salesData = JSON.parse(this._decode(this.payId));
    // console.log('salesData', this.salesData)
  },
  methods: {
    sales() {
    },
    submit() {
      let medics_available_timesid = this.salesData.medicsAvailableId;
      let amount = this.salesData.price;
      let provider = "cash";
      let data = {medics_available_timesid, amount, provider};
      console.log('data: ', data);

      this.snack.bar = true;
      this.snack.text = "Updating accounts";
      let self = this;
      Restful.checkout
          .cash(data)
          .then((response) => {
            this.snack.color = "success";
            console.log(response);
            if (response.status === 200) {
              this.snack.color = "success";
              this.snack.text = response.data;
              this.snack.timeout = -1;
              setTimeout(() => {
                this.overlay = false;
                this.$router.push({name: 'patient.appointments'})
              }, 6000);
            } else {
              this.snack.color = "error";
              this.snack.text = "Sorry, payment is not successful ";
              this.snack.timeout = -1;
            }
          })
          .catch((error) => {
            self.snack.text = error.response.data.message;
            self.snack.timeout = -1;
            self.snack.color = "error";
            self.overlay = false;
          });

    }
  }
}
</script>