<template>
  <v-dialog v-model="pharmaDialog" max-width="1000" persistent>
    <v-card>
      <v-card-title>{{ offer }}'s offer</v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="view" :items-per-page="5">
          <template v-slot:item.price="{ item }">
            {{ currency(item.price) }}
          </template>
          <template v-slot:item.cost="{ item }">
            {{ currency(item.cost) }}
          </template>
          <template v-slot:item.expiry="{ item }">
            {{ item.expiry | myDate }}
          </template>
          <template v-slot:item.sub_total="{ item }">
            {{ currency(item.sub_total) }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Utils from "../../mixins/utils.js";

export default {
  props: {
    pharmaDialog: Boolean,
    myId: { type: Number },
    data: { type: String },
    offer: { type: String },
  },
  mixins: [Utils],
  data() {
    return {
      view: [],
      headers: [
        { text: "brand_name", value: "brand_name" },
        { text: "description", value: "brand_description" },
        { text: "manufacturer", value: "manufacturer" },
        { text: "expiry", value: "expiry" },
        { text: "price", value: "price" },
        { text: "quantity", value: "quantity" },
        { text: "sub total", value: "sub_total" },
      ],
    };
  },
  created() {
    this.myView();
    console.log("view", this.view);
  },
  methods: {
    myView() {
      this.view = JSON.parse(this._decode(this.data));
      console.log("view", this.view);
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closePharma", payload);
    },
  },
};
</script>
