<template>
  <div class="page pay">
    <div class="heading">
      <v-toolbar :flat="flatApp" dense>
        <v-btn icon @click="menuLink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          class="capitalize"
          style="padding: 14px 20px"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text color="success" v-on="on">
              <v-icon size="25" dark>mdi-bell-outline</v-icon>
              <v-badge
                bordered
                content="3"
                overlap
                style="margin: 5px 10px 0px 10px"
              ></v-badge>
            </v-btn>
          </template>
          <span>Request Response Notification</span>
        </v-tooltip> -->
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-row>
        <v-col cols="12" lg="7">
          <v-card :loading="loading" loader-height="2" outlined>
            <v-toolbar dense flat>
              <v-toolbar-title>{{ details.name }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" text @click="goBack" v-on="on">
                    <v-icon dark size="25">mdi-keyboard-return</v-icon>
                  </v-btn>
                </template>
                <span>Back to Appointment Page</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-toolbar dense flat>
              <v-toolbar-title>
                <span class="cost">Pay:</span>
                <span class="px"> {{ currency(details.cost) }}</span>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text style="padding: 0 7px 14px">
              <v-row align="center" justify="center" style="padding: 0">
                <v-col cols="12" sm="6">
                  <p class="with">Pay With</p>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-btn-toggle v-model="paySelected" active-class="" rounded>
                    <v-btn
                      v-for="item in payMethodList"
                      :key="item.id"
                      style="min-width: 80px"
                      @click.stop="payMethod(item.id)"
                    >
                      <v-icon>{{ payLabel(item.name) }}</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <div class="payNow">
            <div v-if="paySelect === 1">
              <ccPayment v-if="id !== null" :payId="id" />
            </div>
            <div v-else-if="paySelect === 2">
              <momoPayment v-if="id !== null" :payId="id" />
            </div>
            <div v-else-if="paySelect === 3">
              <cashPayment v-if="id !== null" :payId="id" />
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="5">
          <v-card outlined>
            <v-toolbar dense flat>
              <v-toolbar-title>
                <span
                  style="font-weight: 300; color: #808080; padding-right: 5px"
                >
                  Customer:
                </span>
                {{ details.customer }}
              </v-toolbar-title>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <div v-if="myClient.type === 'pharma'">
                <span
                  style="font-weight: 300; color: #808080; padding-right: 5px"
                >
                  Pay To:
                </span>
                {{ subsidiary.subscribersName | capitalize }}
                <v-divider></v-divider>
                <div class="mylist">
                  <ul>
                    <li>
                      <div class="myleft">subsidiary</div>
                      <div class="myright">
                        {{ subsidiary.name | capitalize }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">manager</div>
                      <div class="myright">{{ subsidiary.manager }}</div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">location</div>
                      <div class="myright">
                        {{ subsidiary.physical_location }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">geo-location</div>
                      <div class="myright">
                        <v-btn color="primary" icon>
                          <v-icon>mdi-earth</v-icon>
                        </v-btn>
                      </div>
                      <div class="clearall"></div>
                    </li>

                    <li>
                      <div class="myleft">prescription details</div>
                      <div class="myright">
                        <v-btn
                          color="primary"
                          text
                          x-small
                          @click.stop="pharmaDialog = true"
                        >
                          view detail
                        </v-btn>
                        <CheckoutPharmaScribe
                          v-if="prescribes.length > 0"
                          :data="_encode(JSON.stringify(prescribes))"
                          :offer="subsidiary.name"
                          :pharmaDialog="pharmaDialog"
                          @closePharma="closePharma"
                        />
                      </div>
                      <div class="clearall"></div>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-else-if="myClient.type === 'labs'">
                <span
                  style="font-weight: 300; color: #808080; padding-right: 5px"
                  >Pay To:
                </span>
                {{ subsidiary.subscribersName | capitalize }}
                <v-divider></v-divider>
                <div class="mylist">
                  <ul>
                    <li>
                      <div class="myleft">subsidiary</div>
                      <div class="myright">
                        {{ subsidiary.name | capitalize }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">manager</div>
                      <div class="myright">{{ subsidiary.manager }}</div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">location</div>
                      <div class="myright">
                        {{ subsidiary.physical_location }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">geo-location</div>
                      <div class="myright">
                        <v-btn color="primary" icon>
                          <v-icon>mdi-earth</v-icon>
                        </v-btn>
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">prescription details</div>
                      <div class="myright">
                        <v-btn
                          color="primary"
                          text
                          x-small
                          @click.stop="openLab"
                        >
                          view detail
                        </v-btn>
                        <CheckoutLabScribe
                          v-if="prescribes.length > 0"
                          :data="_encode(JSON.stringify(prescribes))"
                          :labDialog="labDialog"
                          :offer="subsidiary.name"
                          @closeLab="closeLab"
                        />
                      </div>
                      <div class="clearall"></div>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-else-if="myClient.type === 'provider'">
                <span
                  style="font-weight: 300; color: #808080; padding-right: 5px"
                >
                  Pay To:
                </span>
                {{ subsidiary.salut | capitalize }}
                {{ subsidiary.first_name | capitalize }}
                {{ subsidiary.mid_name | capitalize }}
                {{ subsidiary.last_name | capitalize }}
                <v-divider></v-divider>
                <div class="mylist">
                  <ul>
                    <li>
                      <div class="myleft">Business location</div>
                      <div class="myright">
                        {{ subsidiary.business_name | capitalize }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">city</div>
                      <div class="myright">
                        {{ subsidiary.city | capitalize }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">location</div>
                      <div class="myright">{{ subsidiary.geolocation }}</div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">geo-location</div>
                      <div class="myright">
                        <v-btn color="primary" icon>
                          <v-icon>mdi-earth</v-icon>
                        </v-btn>
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">initial complaints</div>
                      <div class="myright">
                        {{ prescribes }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">service type</div>
                      <div
                        class="myright"
                        style="text-transform: uppercase; font-size: 0.8em"
                      >
                        {{ details.service_type }}
                      </div>
                      <!--  -->
                      <div class="clearall"></div>
                    </li>
                  </ul>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-snackbar
      v-model="snack.bar"
      :color="snack.color"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import WinScroll from "../../mixins/windowscroll";
import Util from "../../mixins/utils.js";
import Restful from "@/services/RestFul";
import CheckoutLabScribe from "../../components/checkout/labscribe.vue";
import CheckoutPharmaScribe from "../../components/checkout/pharmaScribe.vue";
import ccPayment from "@/components/checkout/payments/ccPayment.vue";
import momoPayment from "@/components/checkout/payments/momoPayment.vue";
import cashPayment from "@/components/checkout/payments/cashPayment.vue";

export default {
  name: "pay",
  components: {
    CheckoutLabScribe,
    CheckoutPharmaScribe,
    ccPayment,
    momoPayment,
    cashPayment,
  },

  mixins: [Util, WinScroll("position")],
  props: {
    id: { type: String },
  },
  data: () => ({
    crumbs: [
      {
        exact: true,
        link: true,
        text: "Dashboard",
        to: { name: "medics.dash" },
      },
      {
        exact: true,
        link: true,
        text: "Appointment",
        to: { name: "patient.appointments" },
      },
      {
        exact: true,
        link: true,
        text: "Checkout: Pay Now",
        to: { name: "pay.now" },
      },
    ],
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    labDialog: false,
    pharmaDialog: false,
    loading: false,
    myClient: {},
    details: {},
    prescribes: [],
    subsidiary: {},
    payToggle: null,
    myPayments: null,
    payMethodList: [],
    // payDisplay: "",
    myPayMethod: 1,
    paySelected: null,
    paySelect: null,
    explainMethod: "",
  }),
  computed: {
    flatApp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    "pay.payment_method": function (a) {
      console.log("a", a);
      this.explainMethod = a.description;
      this.payDisplay = a.name;
    },
  },
  created() {
    this.sender();
    this.find();
    // this.serviceName();
    // this.find();
    this.payMethods();
  },
  methods: {
    menuLink() {
      this.$emit("sidebar");
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    sender() {
      let me = JSON.parse(this._decode(this.id));
      console.log(me);
      this.myClient = me;
    },
    find() {
      let d = JSON.parse(this._decode(this.id));
      this.loading = true;
      console.log("_decode", d);
      let self = this;
      Restful.checkout
        .checkout(d)
        .then((response) => {
          console.log(response);
          this.details = response.data;
          this.prescribes = response.data.prescribes;
          this.subsidiary = response.data.subsidiary;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          // self.snack.color = "error";
          // self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    payMethods() {
      Restful.general.paymentMethod
        .list()
        .then((response) => {
          console.log(response);
          this.payMethodList = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    payLabel(name) {
      if (name === "creditcard") {
        return "mdi-credit-card-outline";
      } else if (name === "momo") {
        return "mdi-cellphone-basic";
      } else if (name === "cash") {
        return "mdi-cash";
      }
    },
    payMethod(id) {
      this.paySelect = id;
      // console.log(id)
      // if (id === 0) {
      // this.paySelect = id;
      // } else if (id === 1) {
      //   this.myPayMethod = 2;
      // } else if (id === 2) {
      //   this.myPayMethod = 3;
      // }
      return id;
    },
    openLab() {
      this.labDialog = true;
    },
    closeLab(p) {
      this.labDialog = p.state;
    },
    closePharma(p) {
      this.pharmaDialog = p.state;
    },
  },
};
</script>
